import * as React from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

export default function BasicSwitch(props) {
    return (
        <div>
            <FormGroup>
                <FormControlLabel
                    control={
                        <Switch
                            onChange={props.onChange}
                            checked={props.checked}
                        />
                    }
                    label={<span> {props.label} </span>}
                    sx={{fontFamily: "'Helvetica', 'Arial', sans-serif", color: 'navy'}}
                />
            </FormGroup>
        </div>
    );
}