import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import EditIcon from '@mui/icons-material/Edit';

export default function TagsListCheckBox(props) {
  const [checked, setChecked] = React.useState([]);

  React.useEffect(() => {
    let newChecked = [];
    props.tags.forEach((tag) => {
      if (props.isTagIncluded(tag.uid) || props.isTagExcluded(tag.uid)) {
        newChecked.push(tag);
      }
    });
    setChecked(newChecked);
  }, [props.includeTags, props.excludeTags]);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      // include
      props.addToIncludeExclude(value.uid, "include");
      newChecked.push(value);
    } else {
      if (props.isTagIncluded(value.uid)) {
        // still want it checked but with an exclude checkbox i.e. changes colour
        props.removeFromIncludeExclude(value.uid, "include");
        props.addToIncludeExclude(value.uid, "exclude");
      } else if (props.isTagExcluded(value.uid)) {
        props.removeFromIncludeExclude(value.uid, "exclude");
        newChecked.splice(currentIndex, 1);
      }
    }
    setChecked(newChecked);
  };

  return (
    <List sx={{ width: "100%", maxWidth: 360, bgcolor: "#282c34" }}>
      {props.tags
        .sort((a, b) => {
          const aId = a.id.split(".").map((idPart) => parseInt(idPart));
          const bId = b.id.split(".").map((idPart) => parseInt(idPart));

          for (let i = 0; i < aId.length; i++) {
            if (aId[i] > bId[i]) return 1;
            else if (aId[i] < bId[i]) return -1;
          }

          return 0;
        })
        .map((tag) => {
          const labelId = `checkbox-list-label-${tag.uid}`;

          return (
            <ListItem
              key={tag.uid}
              secondaryAction={
                <IconButton edge="end" aria-label="comments">
                  <EditIcon sx={{ color: "white" }} />
                </IconButton>
              }
              disablePadding
            >
              <ListItemButton
                role={undefined}
                onClick={handleToggle(tag)}
                dense
              >
                <ListItemIcon>
                  <Checkbox
                    sx={{ color: "white" }}
                    edge="start"
                    color={props.isTagExcluded(tag.uid) ? "warning" : "success"}
                    checked={checked.indexOf(tag) !== -1}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ "aria-labelledby": labelId }}
                  />
                </ListItemIcon>
                <ListItemText
                  id={labelId}
                  primaryTypographyProps={{
                    fontWeight: "bold",
                    color: "white",
                  }}
                  secondaryTypographyProps={{ color: "white" }}
                  primary={`${props.allTags[tag.uid].id} - [${
                    props.allTags[tag.uid].questions
                      ? Object.keys(props.allTags[tag.uid].questions).length
                      : 0
                  }]`}
                  secondary={`${props.allTags[tag.uid].topic}`}
                />
              </ListItemButton>
            </ListItem>
          );
        })}
    </List>
  );
}
