import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
    getQuestionsByLevelAndYear,
    getTagsDetailByLevel,
    getImageURL,
} from "../api";
import CircularIndeterminate from "../components/ProgressColour";
import MediaCard from "../components/MediaCard";
import AddNewQuestion from "../components/AddNewQuestion";
// TODO: changing fields on edit quetsion dialogue, then exiting, and reopening again
//       results in the text remaining (possibly because component is not unmounted - simply hidden)
export default function ConfigureQuestions() {
    const navigate = useNavigate();
    const { level, year } = useParams();

    const [questions, setQuestions] = useState({});
    const [tags, setTags] = useState({});

    const [cards, setCards] = useState([]);
    const [loading, setLoading] = useState(true);

    // There are some questions in a particular level which have tags outside that level
    // e.g. a 2U question has 3U tags
    // As a result, all tags need to be loaded in
    useEffect(() => {
        async function fetchTagsData() {
            const tagsResponse = await Promise.allSettled([
                getTagsDetailByLevel("2U"),
                getTagsDetailByLevel("3U"),
                getTagsDetailByLevel("4U"),
            ]);

            const newTags = {
                ...tagsResponse[0].value,
                ...tagsResponse[1].value,
                ...tagsResponse[2].value,
            };

            setTags(newTags);
        }
        async function fetchQuestionsData() {
            const questionsResponse = await getQuestionsByLevelAndYear(
                level,
                year
            );
            setQuestions(questionsResponse);
        }

        fetchTagsData();
        fetchQuestionsData();
    }, []);

    useEffect(() => {
        const loadData = async () => {
            const cards = await displayQuestionCards();
            setCards(cards);
            setLoading(false);
        };

        loadData();
    }, [questions, tags]);

    function isQuestionMCQ(question) {
        if (question.name) {
            return (
                question.name.trim().split(" ").length === 1 &&
                parseInt(question.name) <= 10 &&
                question.year >= 2012
            );
        } else {
            return (
                question.question.trim().split(" ").length === 1 &&
                parseInt(question.question) <= 10 &&
                question.year >= 2012
            );
        }
    }

    function deromanize(str) {
        str = str.toUpperCase();

        let validator =
            /^M*(?:D?C{0,3}|C[MD])(?:L?X{0,3}|X[CL])(?:V?I{0,3}|I[XV])$/;
        let token = /[MDLV]|C[MD]?|X[CL]?|I[XV]?/g;
        let key = {
            M: 1000,
            CM: 900,
            D: 500,
            CD: 400,
            C: 100,
            XC: 90,
            L: 50,
            XL: 40,
            X: 10,
            IX: 9,
            V: 5,
            IV: 4,
            I: 1,
        };
        let num = 0,
            m;

        if (!(str && validator.test(str))) return 0;
        while ((m = token.exec(str))) num += key[m[0]];
        return num;
    }

    async function displayQuestionCards() {
        let cards = [];

        Object.keys(questions)
            .sort((a, b) => {
                a = questions[a];
                b = questions[b];

                const isMCQA = isQuestionMCQ(a);
                const isMCQB = isQuestionMCQ(b);

                if (isMCQA && isMCQB)
                    return (
                        parseInt(a.question) - parseInt(b.question) ||
                        a.year - b.year
                    );
                else if (isMCQA) return -1;
                else if (isMCQB) return 1;

                // neither questions are MCQs - weight by question number
                const aNameSplit = a.question.trim().split(" ");
                const bNameSplit = b.question.trim().split(" ");

                let i = 0;
                while (i < 3) {
                    if (
                        aNameSplit.length - 1 < i ||
                        bNameSplit.length - 1 < i
                    ) {
                        const diff = aNameSplit.length - bNameSplit.length;
                        if (diff !== 0) return diff;
                        break;
                    }

                    const currAStr = aNameSplit[i].replace(/[()]+/g, "");
                    const currBStr = bNameSplit[i].replace(/[()]+/g, "");

                    if (i === 0) {
                        // compare numbers
                        const diff = parseInt(currAStr) - parseInt(currBStr);
                        if (diff !== 0) return diff;
                    } else if (i === 1) {
                        // compare letters
                        const diff = currAStr.localeCompare(currBStr);
                        if (diff !== 0) return diff;
                    } else if (i === 2) {
                        return deromanize(currAStr) - deromanize(currBStr);
                    }
                    i++;
                }

                return a.year - b.year;
            })
            .forEach((question) => {
                cards.push(
                    <MediaCard
                        key={question}
                        tags={tags}
                        questionName={questions[question].question}
                        marks={questions[question].marks}
                        question={{ ...questions[question] }}
                        associatedTagsJSX={Object.keys(
                            questions[question].tags
                        ).map((t) => {
                            return (
                                <span key={tags[t].id}>
                                    <span>
                                        {" "}
                                        {tags[t].id +
                                            " - " +
                                            tags[t].topic +
                                            " (" +
                                            tags[t].description +
                                            ")"}{" "}
                                    </span>
                                    <br></br>
                                </span>
                            );
                        })}
                        image={getImageURL(question, "question")}
                    ></MediaCard>
                );
            });

        return cards;
    }

    return (
        <div>
            <AddNewQuestion></AddNewQuestion>
            {loading ? (
                <CircularIndeterminate></CircularIndeterminate>
            ) : (
                <div className="mediaCards">{!loading && cards}</div>
            )}
        </div>
    );
}
