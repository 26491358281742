import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TagsListCheckBox from "./TagsListCheckBox";

export default function TagsDialogue(props) {
  const [open, setOpen] = React.useState(true);

  // act as a buffer that only adds to the main include/exclude set upon confirmation
  const [includedTagsInDescription, setIncludedTagsInDescription] =
    React.useState(new Set());
  const [excludedTagsInDescription, setExcludedTagsInDescription] =
    React.useState(new Set());

  React.useEffect(() => {
    // synchronise selected tags
    setIncludedTagsInDescription(
      new Set(props.getAllIncludeExcludeTags("include"))
    );
    setExcludedTagsInDescription(
      new Set(props.getAllIncludeExcludeTags("exclude"))
    );
  }, []);

  const handleClose = () => {
    props.closeAction();
    setOpen(false);
  };

  const handleConfirm = () => {
    // add to main parent set
    props.setIncludeExclude(includedTagsInDescription, "include");
    props.setIncludeExclude(excludedTagsInDescription, "exclude");

    handleClose();
  };

  /**
   *
   * @param {array} tags string of ids
   * @param {string} addTo include | exclude
   */
  const addToIncludeExclude = (tagid, addTo) => {
    let copySet;
    if (addTo === "include") {
      copySet = new Set(includedTagsInDescription);
    } else if (addTo === "exclude") {
      copySet = new Set(excludedTagsInDescription);
    } else {
      return;
    }

    copySet.add(tagid);

    // can only be include/exclude at this point
    addTo === "include"
      ? setIncludedTagsInDescription(copySet)
      : setExcludedTagsInDescription(copySet);
  };

  const removeFromIncludeExclude = (tagid, removeFrom) => {
    let copySet;
    if (removeFrom === "include") {
      copySet = new Set(includedTagsInDescription);
    } else if (removeFrom === "exclude") {
      copySet = new Set(excludedTagsInDescription);
    } else {
      return;
    }

    let toRemove;

    for (let t of copySet) {
      if (t === tagid) {
        toRemove = t;
        break;
      }
    }

    if (toRemove) copySet.delete(toRemove);

    // can only be include/exclude at this point
    removeFrom === "include"
      ? setIncludedTagsInDescription(copySet)
      : setExcludedTagsInDescription(copySet);
  };

  const isTagIncluded = (id) => {
    for (let t of includedTagsInDescription) {
      if (t === id) return true;
    }
    return false;
  };

  const isTagExcluded = (id) => {
    for (let t of excludedTagsInDescription) {
      if (t === id) return true;
    }
    return false;
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll="body"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle
          id="scroll-dialog-title"
          sx={{ color: "white", backgroundColor: "#282c34" }}
        >
          Select Tags
        </DialogTitle>
        <DialogContent sx={{ backgroundColor: "#282c34" }}>
          <TagsListCheckBox
            allTags={props.allTags}
            tags={props.tags()}
            includeTags={includedTagsInDescription}
            excludeTgas={excludedTagsInDescription}
            addToIncludeExclude={addToIncludeExclude}
            removeFromIncludeExclude={removeFromIncludeExclude}
            isTagIncluded={isTagIncluded}
            isTagExcluded={isTagExcluded}
          ></TagsListCheckBox>
        </DialogContent>
        <DialogActions sx={{ backgroundColor: "#282c34" }}>
          <Button onClick={handleClose} sx={{ color: "white" }}>
            Cancel
          </Button>
          <Button onClick={handleConfirm} sx={{ color: "white" }}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
