import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import AllTagsTree from "./AllTagsTree";

import "../css/collapsableTree.css";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function FormDialog(props) {
    const [open, setOpen] = React.useState(false);

    const [currentName, setCurrentName] = React.useState(null);
    const [currentYear, setCurrentYear] = React.useState(null);
    const [currentMarks, setCurrentMarks] = React.useState(null);
    const [currentTags, setCurrentTags] = React.useState(new Set());
    
    React.useEffect(() => {
        setCurrentName(props.question.question);
        setCurrentMarks(props.question.marks);
        setCurrentYear(props.question.year);
    }, [props.question.question, props.question.year, props.question.marks]);
    
    React.useEffect(() => {
        setCurrentTags(new Set(Array.from(Object.keys(props.question.tags))));
    }, [props.question.tags]);
    
    function getCurrentTags() {
        return Array.from(currentTags);
    }
    
    function changeCurrentTagsTo(tags) {
        setCurrentTags(new Set(tags));
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        // TODO: post request to backend to confirm changes

        // changed tags in currentTags
        setOpen(false);
    };

    return (
        <div>
            <Button onClick={handleClickOpen}>{props.buttonMessage}</Button>

            <Dialog
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
                maxWidth={"350px"}
            >
                <DialogTitle> Edit Question </DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        required
                        margin="dense"
                        id="name"
                        label="Name"
                        fullWidth
                        variant="filled"
                        helperText="e.g. 1 (d) (ii)"
                        defaultValue={currentName}
                        onChange={(e) => {setCurrentName(e.target.value)}}
                    />

                    <TextField
                        required
                        margin="dense"
                        id="year"
                        label="Year"
                        type="number"
                        fullWidth
                        variant="filled"
                        defaultValue={currentYear}
                        onChange={(e) => {setCurrentYear(e.target.value)}}
                        />

                    <TextField
                        required
                        margin="dense"
                        id="marks"
                        label="Marks"
                        type="number"
                        fullWidth
                        variant="filled"
                        defaultValue={currentMarks}
                        onChange={(e) => {setCurrentMarks(e.target.value)}}
                    />

                    <div className="treeSelectTags">
                        <AllTagsTree
                            getCurrentTags={getCurrentTags}
                            changeCurrentTagsTo={changeCurrentTagsTo}
                            tags={props.tags}
                        ></AllTagsTree>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleClose}>Apply Changes</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
