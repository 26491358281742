import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import FullScreenDialog from "../components/FullScreenDialogue";
import { getAllYears, getTagsDetailByLevel } from "../api";
import BasicSelect from "../components/Select";
import { Button } from "@mui/material";

import "../css/configurePaper.css";
import "../css/adminConfig.css";

export default function ConfigureOptionSelect() {
    const navigate = useNavigate();

    const [level, setLevel] = useState("2U");
    const [years, setYears] = useState([]);

    useEffect(() => {
        const token = localStorage.getItem("token");
        if (!token) navigate("/");
    }, [localStorage]);

    useEffect(() => {
        async function fetchYears() {
            const yearsResponse = await getAllYears();
            setYears(yearsResponse);
        }

        fetchYears();
    }, []);

    function handleYearSelection(year) {
        navigate(`/configure/${level}/${year}`)
    }

    return (
        <div>
            <div className="configurePaper">
                <BasicSelect
                    options={["2U", "3U", "4U"]}
                    initialValue={level}
                    setLevel={setLevel}
                />
            </div>

            <hr></hr>
            
            <div className="yearSelection">
                {years.map((year) => {
                    return (
                        <Button
                            key={year}
                            sx={{
                                height: "40px",
                                color: "black",
                            }}
                            onClick={() => {handleYearSelection(year)}}
                        >
                            {year}
                        </Button>
                    );
                })}
            </div>
        </div>
    );
}
