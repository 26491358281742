import "./App.css";

import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import { Fragment } from "react";

import PDFGenerator from "./pages/PDFGenerator";
import ErrorPage from "./pages/ErrorPage";
import Login from "./pages/Login";
import ConfigureOptionSelect from "./pages/ConfigureOptionSelect";
import ConfigureQuestions from "./pages/ConfigureQuestions";
import Home from "./pages/Home";

export default function App() {
    return (
        <div className="main-app">
        <Router>
            <header className="App-header">
                <Link to="/" className="logoLinkLeft">
                    <img src={require("./images/fe_logo.jpg")} className="imageLogo"/>
                </Link>
                <Link to="/" className="App-name"> HSC MATHS BY TOPIC </Link>
                <Link to="/" className="logoLinkRight">
                    <img src={require("./images/fe_logo.jpg")} className="imageLogo"/>
                </Link>
            </header>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/paper/:level" element={<PDFGenerator />} />
                <Route path="/login" element={<Login />} />
                <Route path="/configure/" element={<ConfigureOptionSelect />} />
                <Route
                    path="/configure/:level/:year"
                    element={<ConfigureQuestions />}
                />
                <Route path="*" element={<ErrorPage />} />
            </Routes>
            {/* <nav>
                    <Link to="/admin"> Admin </Link>
                </nav> */}
            {/* <div className="footer">
                <BasicButtons
                    text={"Admin Dashboard"}
                    onClick={(e) => setFullScreenDialogue(true)}
                ></BasicButtons>
            </div> */}
        </Router>

        </div>
    );
}
