import * as React from "react";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import BasicSwitch from "./LabelSwitch";
import BasicSelect from "./Select";

import "../css/configurePaper.css";
import { useState, useEffect } from "react";

export default function EditSettingsDialogue(props) {
    let { split, formula, marks } = props.getCurrentPDFSettings();

    useEffect(() => {
        const newSettings = props.getCurrentPDFSettings();
        split = newSettings.split;
        formula = newSettings.formula;
        marks = newSettings.marks;
    }, [props.getCurrentPDFSettings()]);

    const setLevel = (lvl) => {
        const { split, formula, marks } = props.getCurrentPDFSettings();
        props.changePDFSettings(split, formula, marks, lvl);
    };

    return (
        <div className="configurePaper">
            <DialogContent>
                {/* <BasicTextFields variant="Standard" label="Marks" updateState={false} /> */}
                <TextField
                    autoFocus
                    fullWidth
                    defaultValue={marks}
                    margin="dense"
                    size="small"
                    id="name"
                    label="Marks"
                    type="number"
                    variant="outlined"
                    inputProps={{ style: { fontFamily: "Helvetica", color: "navy" } }}
                    InputLabelProps={{ style: { fontFamily: "Helvetica", color: "navy" } }}
                    onChange={(e) => {
                        const { split, formula, marks, level } =
                        props.getCurrentPDFSettings();
                        props.changePDFSettings(
                            split,
                            formula,
                            e.target.value,
                            level
                            );
                        }}
                        />

                <BasicSwitch
                    label={"Split Questions & Solutions"}
                    checked={split}
                    defaultChecked={split}
                    onChange={(e) => {
                        const { split, formula, marks, level } =
                        props.getCurrentPDFSettings();
                        props.changePDFSettings(!split, formula, marks, level);
                    }}
                ></BasicSwitch>

                <BasicSwitch
                    label={"Include Formula Sheet"}
                    defaultChecked={formula}
                    checked={formula}
                    onChange={(e) => {
                        const { split, formula, marks, level } =
                            props.getCurrentPDFSettings();
                        props.changePDFSettings(split, !formula, marks, level);
                    }}
                ></BasicSwitch>
            </DialogContent>
        </div>
    );
}
