import * as React from 'react';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';


export default function Progress() {
  return (
    <Stack sx={{ color: 'grey.500', margin: 'auto', paddingTop: '20px'}} spacing={2}>
      <CircularProgress color="inherit"/>
    </Stack>
  );
}