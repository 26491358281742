import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import "../css/fullcarddialogue.css";
import AlertDialogSlide from './AlertDialogueSlide';
import FormDialog from './FormDialog';

export default function MediaCard(props) {
	return (
		<div>
			<Card raised={true} sx={{ maxWidth: 500, color: 'black'}}>
				<CardMedia
					component="img"
					image={props.image}
					alt="Image not found."
				/>
				<CardContent>
					<div className='mediaCardText'>
						<Typography gutterBottom variant="subtitle1" component="div">
							{props.questionName}
						</Typography>
						<Typography gutterBottom variant="subtitle1" component="div">
							{props.marks} mark(s)
						</Typography>

					</div>

				</CardContent>
				<CardActions className='cardActions'>
					{/* <AlertDialogSlide
						buttonText="View Tags"
						dialogueJSX={props.associatedTagsJSX}
					>
					</AlertDialogSlide> */}

					<FormDialog buttonMessage="Edit" question={props.question} tags={props.tags}> </FormDialog>
					<Button> Delete </Button> {/* TODO: delete req. - include confirmation dialogue */}
				</CardActions>
			</Card>

		</div>
	);
}