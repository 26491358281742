import TreeNode from "rc-tree";
import "rc-tree/assets/index.css";
import { useEffect, useState } from "react";
import "../css/collapsableTree.css";

export default function AllTagsTree(props) {
    // tags={tags}
    // allTags={tags}
    // tags={getTagsToDisplayInDialogue}
    // getAllIncludeExcludeTags={getAllIncludeExcludeTags}
    // addToIncludeExclude={addToIncludeExclude}
    // setIncludeExclude={setIncludeExclude}

    const [treeData, setTreeData] = useState([]);

    // checkedIDs: string[]
    // info: {
    //     checked: boolean
    //     node: {
    //            key: String
    //            ...
    //      }
    // }
    function handleOnTagCheck(checkedIDs, info) {
        props.changeCurrentTagsTo(checkedIDs);
    }

    function getCheckedKeys() {
        return props.getCurrentTags();
    }

    useEffect(() => {
        let newTreeData = [];
        Object.keys(props.tags)
            // .sort((a, b) => a.localeCompare(b))
            .forEach((tagid) => {
                const currTag = props.tags[tagid];
                let tagInfo = {
                    key: tagid,
                    title: `${currTag.level} | ${currTag.description} (${currTag.id})`,
                    children: [],
                };

                newTreeData.push(tagInfo);
            });

        setTreeData(newTreeData);
    }, [props.tags]);

    return (
        <div>
            <TreeNode
                className="collapsableTree"
                defaultExpandAll={false}
                treeData={treeData}
                checkable={true}
                checkedKeys={getCheckedKeys()}
                selectable={false}
                showIcon={false}
                showLine={true}
                onCheck={handleOnTagCheck}
            ></TreeNode>
        </div>
    );
}
