export default [
    "Working with Functions",
    "Further Work with Functions",
    "Trigonometry and Measure of Angles",
    "Trigonometric Functions and Identities",
    "Logarithms and Exponentials",
    "Introduction to Differentiation",
    "Polynomials",
    "Probability and Discrete Probability Distributions",
    "Working with Combinatorics",
    "Rates of Change",
    "Inverse Trigonometric Functions",
    "Further Trigonometric Identities",
    "Further Trigonometry",
    "Modelling Financial Situations",
    "Proof by Mathematical Induction",
    "Graphing Techniques",
    "Trigonometric Functions and Graphs",
    "Applications of Differentiation",
    "Differential Calculus",
    "Integral Calculus",
    "Introduction to Vectors",
    "Further Calculus Skills",
    "Applications of Calculus",
    "Descriptive Statistics and Bivariate Data Analysis",
    "Random Variables",
    "The Binomial Distribution",
    "Once Off Questions",
    "Pre 2020 Syllabus",
];
