import TreeNode from "rc-tree";
import { useEffect, useState, useRef } from "react";
import tagsOrdering from "../tagsOrdering";
import Mark from "mark.js";

import "rc-tree/assets/index.css";
import "../css/collapsableTree.css";
import "../css/searchBar.css";
import "../css/mark.css";

export default function CollapsableTree(props) {
    const ONCE_OFF_DESC = "Once Off Questions";
    const Z_PRE_2020_DESC = "Z Pre 2020 Syllabus";
    const PRE_2020_DESC = "Pre 2020 Syllabus";

    const MATHEMATICS_ADVANCED = "2U";
    const MATHEMATICS_EXTENSION_1 = "3U";
    const MATHEMATICS_EXTENSION_2 = "4U";

    const KEY_ADV_QUESTIONS_IN_EXT1 = "Maths Advanced Questions in Extension 1";
    const KEY_ADV_QUESTIONS_IN_EXT2 = "Maths Advanced Questions in Extension 2";
    const KEY_EXT1_QUESTIONS_IN_EXT2 = "Maths Extension 1 Questions in Extension 2";

    const APPLICATIONS_OF_CALC_TO_MECHANICS_ID = "06-MEX-M1.3.01";
    const SEARCH_BAR_PLACEHOLDER = "Find a topic...";

    const treeData = useRef(undefined);
    const [checkedKeys, setCheckedKeys] = useState([]);
    const [expandedKeys, setExpandedKeys] = useState([]);

    const [searchQuery, setSearchQuery] = useState("");

    function handleSecondTreeTagSort(a, b, descriptions) {
        // ext2 should have same ordering as ext1 after
        // the last ext2 specific topic (applications to mechanics)
        if (
            props.getLevel() === MATHEMATICS_EXTENSION_2 &&
            APPLICATIONS_OF_CALC_TO_MECHANICS_ID.localeCompare(
                descriptions[a][0].id
            ) < 0 &&
            APPLICATIONS_OF_CALC_TO_MECHANICS_ID.localeCompare(
                descriptions[b][0].id
            ) < 0
        ) {
            for (let d of tagsOrdering) {
                if (d === a) return -1;
                else if (d === b) return 1;
            }
            return 0;
        }

        return descriptions[a][0].id.localeCompare(descriptions[b][0].id);
    }

    const setUp = () => {
        const descriptions = props.getDescriptions();
        if (
            descriptions === undefined ||
            Object.keys(descriptions).length === 0
        )
            return;
        const currLevel = props.getLevel();
        let newTreeData = [];

        // ensure the following tags are appended at the end
        let onceOffQuestions = {};
        let pre2020Questions = {};

        Object.keys(descriptions)
            .sort((a, b) => handleSecondTreeTagSort(a, b, descriptions))
            .forEach((description) => {
                let mainDesc = {
                    key: description,
                    title: description,
                    children: [],
                    disabled: false,
                };

                let mainChildren = [];
                descriptions[description]
                    .filter((a) => {
                        if (a.level === currLevel) return a;
                        return null;
                    })
                    .sort((a, b) => {
                        return a.id.localeCompare(b.id);
                    })
                    .forEach((tag) => {
                        mainChildren.push({
                            key: tag.uid,
                            title: tag.topic,
                            children: [],
                            disabled: false,
                        });
                    });

                mainDesc["children"] = mainChildren;

                if (
                    description === ONCE_OFF_DESC ||
                    description === PRE_2020_DESC ||
                    description === Z_PRE_2020_DESC
                ) {
                    // should be greyed out as these descriptions are non standard course items
                    mainDesc.className = "non-course-descriptions";

                    // need to be at the end only
                    if (description === ONCE_OFF_DESC) {
                        mainDesc.key = mainDesc.children[0].key; // since descriptions do not have keys, replace with child key
                        mainDesc["children"] = []; // "Once off Questions" should not include its child
                        onceOffQuestions = mainDesc;
                    }
                    else pre2020Questions = mainDesc;
                } else {
                    newTreeData.push(mainDesc);
                }
            });

        // group together topics/descriptions by level
        if (currLevel === MATHEMATICS_ADVANCED) {
            for (let t of newTreeData) {
                applyStylesToTreeNode(t, "advancedTopics");
            }

            // styles not applied to greyed out items
            newTreeData.push(onceOffQuestions);
            newTreeData.push(pre2020Questions);
            treeData.current = newTreeData;
        } else if (currLevel === MATHEMATICS_EXTENSION_1) {
            let advTopics = [];
            let remainingTopics = [];

            for (let t of newTreeData) {
                if (t.children.length === 0) continue;

                if (descriptions[t.title][0].id.indexOf("MA") !== -1) {
                    advTopics.push(applyStylesToTreeNode(t, "advancedTopics"));
                } else if (descriptions[t.title][0].id.indexOf("ME") !== -1) {
                    remainingTopics.push(applyStylesToTreeNode(t, "extension1Topics"));
                }
            }

            const allData = [
                {
                    key: KEY_ADV_QUESTIONS_IN_EXT1,
                    title: KEY_ADV_QUESTIONS_IN_EXT1,
                    children: advTopics,
                    disabled: false,
                    className: "advancedTopics",
                },
                ...remainingTopics,
                onceOffQuestions,
                pre2020Questions
            ];

            treeData.current = allData;
        } else if (currLevel === MATHEMATICS_EXTENSION_2) {
            let advTopics = [];
            let ext1Topics = [];
            let remainingTopics = [];

            for (let t of newTreeData) {
                if (t.children.length === 0) continue;

                if (descriptions[t.title][0].id.indexOf("MA") !== -1) {
                    advTopics.push(applyStylesToTreeNode(t, "advancedTopics"));
                } else if (
                    descriptions[t.title][0].id.indexOf("ME") !== -1 &&
                    descriptions[t.title][0].id.indexOf("MEX") === -1
                ) {
                    ext1Topics.push(applyStylesToTreeNode(t, "extension1Topics"));
                } else {
                    remainingTopics.push(applyStylesToTreeNode(t, "extension2Topics"));
                }
            }

            const allData = [
                {
                    key: KEY_ADV_QUESTIONS_IN_EXT2,
                    title: KEY_ADV_QUESTIONS_IN_EXT2,
                    children: advTopics,
                    disabled: false,
                    className: "advancedTopics",
                },
                {
                    key: KEY_EXT1_QUESTIONS_IN_EXT2,
                    title: KEY_EXT1_QUESTIONS_IN_EXT2,
                    children: ext1Topics,
                    disabled: false,
                    className: "extension1Topics",
                },
                ...remainingTopics,
                onceOffQuestions,
                pre2020Questions
            ];

            treeData.current = allData;
        }
    };

    if (treeData.current === undefined) setUp();

    useEffect(() => {
        if (treeData.current === undefined) return;

        if (props.type === "include") {
            setCheckedKeys(props.allIncludeExcludeTags("include"));
            // exclude tags should not retain any existing state
            props.clearIncludeExclude("exclude");
        } else if (props.type === "exclude") {
            // When user moves backwards from a step further along than
            // exclude topics, use the state already stored.
            // Otherwise, if the user is at a previous step, recreate state.

            if (props.stepDirection <= 0) {
                disableAndSelectByIncludedTags(
                    props.allIncludeExcludeTags("include"),
                    null,
                    true
                );
                setCheckedKeys(props.allIncludeExcludeTags("exclude"));
            } else {
                // disable/tick based on included tags AND
                // tick based on excluded tags state in parent
                disableAndSelectByIncludedTags(
                    props.allIncludeExcludeTags("include"),
                    props.allIncludeExcludeTags("exclude"),
                    false
                );
            }
        }
    }, []);

    // checkedIDs: string[]
    // info: {
    //     checked: boolean
    //     node: {
    //            key: String
    //            ...
    //      }
    // }
    function handleOnTagCheck(checkedIDs, extraToCheck) {
        let toAdd = [];
        for (let id of checkedIDs) {
            // only match uuid
            if (
                !/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/.test(
                    id
                )
            )
                continue;
            toAdd.push(id);
        }

        props.resetIncludeExclude(toAdd, props.type);
        if (extraToCheck && extraToCheck.length > 0)
            setCheckedKeys([...toAdd, ...extraToCheck]);
        else setCheckedKeys(toAdd);
    }

    function applyStylesToTreeNode(treeNode, topicType) {
        // apply styles to parent and all children
        treeNode.className = topicType;
        treeNode.children.forEach((e, i, arr) => {
            arr[i] = { ...e, className: topicType };
        });

        return treeNode;
    }

    const disableAndSelectByIncludedTags = (
        includeTags,
        extraToCheck,
        disableOnly
    ) => {
        // For exclude topics:
        // 1. The topics are auto selected after the last topic you selected in the included topics
        // 2. You can't include and exclude the same topic
        if (props.type === "exclude") {
            let toCheck = [];

            // const includeTags = props.allIncludeExcludeTags("include");
            let totalTagsDisabled = 0;

            for (let k in treeData.current) {
                const currDescriptionsChildren =
                    treeData.current[k]["children"];
                let numChildren = currDescriptionsChildren.length;
                let numDisabled = 0;

                if (totalTagsDisabled === includeTags.length)
                    toCheck.push(treeData.current[k].key);
                for (let c of currDescriptionsChildren) {
                    if (includeTags.includes(c.key)) {
                        c.disabled = true;
                        numDisabled++;
                        totalTagsDisabled++;
                    } else if (totalTagsDisabled === includeTags.length) {
                        toCheck.push(c.key);
                    }
                }

                // a tag with no children should not be distabled if it was not selected as an include tag
                if (numChildren === 0 && !includeTags.includes(treeData.current[k].key)) continue;

                if (numDisabled === numChildren)
                    treeData.current[k].disabled = true;
            }

            disableOnly
                ? handleOnTagCheck(toCheck)
                : handleOnTagCheck(toCheck, extraToCheck);
        }
    };

    useEffect(() => {
        if (!treeData || !treeData.current) return;
        const tree_node = document.getElementById("rc-tree-node");
        if (tree_node == null) return;

        if (searchQuery === "") {
            setExpandedKeys([]);
            const markInstance = new Mark(tree_node);
            markInstance.unmark(); // unmark any existing highlights
            return;
        }
        let parentKeysToExpand = [];

        for (let parent of treeData.current) {
            const key = parent.key;

            // does title match
            // if (parent.title.toLowerCase().indexOf(searchQuery.toLowerCase().trim()) !== -1) {
            // }

            // if any children match, expand tree
            for (let child of parent.children) {
                if (child.title.toLowerCase().indexOf(searchQuery.toLowerCase().trim()) !== -1) {
                    parentKeysToExpand.push(parent.title);
                }
                
                if(child.children.length === 0) continue;
                
                for(let subchild of child.children) {
                    if (subchild.title.toLowerCase().indexOf(searchQuery.toLowerCase().trim()) !== -1) {
                        parentKeysToExpand.push(parent.title); // ensure subchild's parent is expanded
                        parentKeysToExpand.push(child.title);
                    }
                }
            }
        }

        // collapses non matching parents
        setExpandedKeys(parentKeysToExpand);


        const markInstance = new Mark(tree_node);
        markInstance.unmark(); // unmark any existing highlights

        // 0 second timeout waits for tree to render completely
        setTimeout(() => {
            markInstance.mark(searchQuery.toLowerCase(), {
                caseSensitive: false,
                acrossElements: true,
                ignorePunctuation: true,
                wildcards: 'disabled',
            });
        }, 0);


    }, [searchQuery]);

    return (
        <div>
            <div className="searchField">
                <label for="searchBar"></label>
                <input
                    autoFocus
                    id="searchBar"
                    type="text"
                    className="searchBar"
                    placeholder={SEARCH_BAR_PLACEHOLDER}
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                />
            </div>

            <div id="rc-tree-node">
                <TreeNode
                    className="collapsableTree"
                    defaultExpandAll={false}
                    treeData={treeData.current}
                    checkable={true}
                    checkedKeys={checkedKeys}
                    selectable={false}
                    showIcon={false}
                    showLine={true}
                    onCheck={handleOnTagCheck}
                    expandedKeys={expandedKeys}
                    onExpand={(e) => setExpandedKeys(e)}
                ></TreeNode>
            </div>

            <button
                className="reset-topics-button"
                onClick={() => {
                    if (props.type === "include") {
                        props.clearIncludeExclude("include");
                        props.clearIncludeExclude("exclude");
                        setCheckedKeys([]);
                    } else if (props.type === "exclude") {
                        props.clearIncludeExclude("exclude");
                        setCheckedKeys([]);
                    }
                }}
            >
                Reset
            </button>
        </div>
    );
}
