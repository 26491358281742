import "../css/home.css";
import { Link } from "react-router-dom"

export default function Home(props) {
    return (
        <div className="subject-selection">
            <div className="selection">
                <Link to="/paper/2U" className="subject-link subject-link-2U"> Mathematics Advanced </Link>
                <Link to="/paper/3U" className="subject-link subject-link-3U"> Mathematics Extension 1 </Link>
                <Link to="/paper/4U" className="subject-link subject-link-4U"> Mathematics Extension 2 </Link>

            </div>
        </div>
    );
}
