const API_BASE = `https://exam-time-pdf.herokuapp.com/api`;

export async function getAllYears() {
    const response = await fetch(API_BASE + '/years');
    return response.json();
}

export async function getTagsDetailByLevel(level) {
    const response = await fetch(API_BASE + `/tags/${level}`);
    return response.json();
}

export async function getTagDetails(id) {
    const response = await fetch(API_BASE + `/tags/${id}`);
    return response.json();
}

export async function createPDF(pdfName, institution, level, marks, formula, separate, includeTags, excludeTags) {
    const includeArray = Array.from(includeTags);
    const excludeArray = Array.from(excludeTags);

    const requestBody = {
        institution: 'FirstEducation', // TODO: add institution support
        level: level,
        marks: marks,
        includeTags: includeArray,
        excludeTags: excludeArray,
        formula: formula,
        separate: separate
    }

    try {
        const response = await fetch(API_BASE + `/pdf`, {
            method: 'POST',
            cache: 'no-cache',
            body: JSON.stringify(requestBody),
            headers: {
                'Content-Type': 'application/json'
            },
        });

        if(!response.ok) throw new Error("Failed to create PDF");
    
        const blob = await response.blob();
        const file = window.URL.createObjectURL(blob);

        const downloadFile = document.createElement('a');
        downloadFile.style.display = 'none';
        downloadFile.href = file;
        downloadFile.download = separate ? pdfName + ".zip" : pdfName + ".pdf";
        document.body.appendChild(downloadFile);

        downloadFile.click();
        window.URL.revokeObjectURL(file);
        return true;
    } catch(e) {
        return false;
    }
}

export async function getQuestionDetails(id) {
    const response = await fetch(API_BASE + `/questions/${id}`);
    return response.json();
}

export function getImageURL(questionID, type) {
    if(!(type === 'question' || type === 'solution')) return '';
    return `${API_BASE}/files/${questionID}/${type}`;
}

export async function login(email, password) {
    try {
        const response = await fetch(API_BASE + `/users/login`, {
            method: 'POST',
            cache: 'no-cache',
            body: JSON.stringify({
                email: email,
                password: password
            }),
            headers: {
                'Content-Type': 'application/json',
            },
        });

        return response.json();
    } catch(e) {
        return false;
    }
}

export async function getQuestionsByLevelAndYear(level, year) {
    const response =  await fetch(`${API_BASE}/questions?year=${year}&level=${level}`);
    return response.json();
}