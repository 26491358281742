import { useEffect, useState, useCallback } from "react";

import "../css/PDFGenerator.css";
import "../css/includeExclude.css";
import "../css/headerSeparation.css";
import "../css/stepper.css";
import "../css/collapsableTree.css";

import { useNavigate, useParams } from "react-router-dom";

import { createPDF, getTagsDetailByLevel } from "../api";
import Animations from "../components/Animation";
import FullScreenDialog from "../components/FullScreenDialogue";
import TagsDialogue from "../components/TagsDialogue";
import IncludeExcludeAlert from "../components/IncludeExcludeAlert";
import ListDisplay from "../components/ListDisplay";
import CustomizedSnackbars from "../components/Snackbar";
import EditSettingsDialogue from "../components/EditSettingsDialogue";
import CustomizedSteppers from "../components/Stepper";
import LoadingButtons from "../components/PDFConfirmationButton";
import Tree from "../components/CollapsableTree";

import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";

import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import FloatingActionButtons from "../components/FloatingActionButton";
import { Fragment } from "react";
import Progress from "../components/Progress";

const NO_TAG_DESCRIPTION_NAME = "Any questions with this tag have no other tags.";
const NUM_MIN_MARKS = 0;
const NUM_MAX_MARKS = 1000;

// id is of the form x.x.x
function sortTagsByIDStr(a, b) {
    return a.localeCompare(b);
    // const aId = a.split(".").map((idPart) => parseInt(idPart));
    // const bId = b.split(".").map((idPart) => parseInt(idPart));

    // for (let i = 0; i < aId.length; i++) {
    //     if (aId[i] > bId[i]) return 1;
    //     else if (aId[i] < bId[i]) return -1;
    // }

    // return 0;
}

function transformDataByDescription(tags) {
    // tags may not be retrieved yet
    if (tags === undefined || Object.keys(tags).length === 0) return {};

    let convertedData = {
        // description1: [...tags],
    };

    for (let [k, v] of Object.entries(tags)) {
        if (!(v.description in convertedData)) {
            convertedData[v.description] = [];
        }

        convertedData[v.description].push({
            uid: k,
            ...v,
        });
    }

    return convertedData;
}

function parseTags(tags) {
    const parsedTags = {};
    for(let t in tags) {
        if(tags[t].description === NO_TAG_DESCRIPTION_NAME) continue;
        parsedTags[t] = tags[t];
    }
    return parsedTags;
}

function App() {
    const { level } = useParams();
    const navigate = useNavigate();

    const PDF_NAME_2U = "Maths Advanced";
    const PDF_NAME_3U = "Maths Extension 1";
    const PDF_NAME_4U = "Maths Extension 2";

    const [split, setSplit] = useState(false);
    const [formula, setFormula] = useState(true);
    const [marks, setMarks] = useState(100);

    const [tags, setTags] = useState({});

    const [descriptions, setDescriptions] = useState({});
    const [currentDescription, setCurrentDescription] = useState(
        "Applications of Differentiation"
    );
    const [tagsDialogue, setTagsDialogue] = useState(false);

    const [includedTags, setIncludedTags] = useState(new Set());
    const [excludedTags, setExcludedTags] = useState(new Set());
    const [tagsLoadingAnimation, setTagsLoadingAnimation] = useState(true);

    const [alertNotEnoughTags, setAlertNotEnoughTags] = useState(false);
    const [alertPDFCreationFailed, setAlertPDFCreationFailed] = useState(false);
    const [pdfCreationInProgress, setPDFCreationInProgress] = useState(false);

    const [displayEditSettingsDialogue, setDisplayEditSettingsDialogue] =
        useState(false);

    const [
        alertNoQuestionsForFullScreenDialogue,
        setAlertNoQuestionsForFullScreenDialogue,
    ] = useState(false);
    // tagid that caused dialogue to open
    const [fullScreenDialogue, setFullScreenDialogue] = useState("");
    const [pdfDownloadName, setPdfDownloadName] = useState("");
    // 0 - 'Include Topics'
    // 1 - 'Exclude Topics'
    // 2 - 'Create'
    const [step, setStep] = useState(0);
    // n > 0, forward
    // n < 0, backwards
    const [stepDirection, setStepDirection] = useState(0);
    
    useEffect(() => {
        setDescriptions(transformDataByDescription(tags));
    }, [tags]);

    useEffect(() => {
        async function fetchData() {
            setTagsLoadingAnimation(true);
            setTags(parseTags(await getTagsDetailByLevel(level)));

            setTagsLoadingAnimation(false);

            setIncludedTags(new Set());
            setExcludedTags(new Set());
        }

        fetchData();
    }, [level]);

    useEffect(() => {
        setPdfDownloadName(`${level}-${marks}-Paper-First-Education`);
        switch (level) {
            case "2U":
                setPdfDownloadName(PDF_NAME_2U);
                break;
            case "3U":
                setPdfDownloadName(PDF_NAME_3U);
                break;
            case "4U":
                setPdfDownloadName(PDF_NAME_4U);
                break;
            default:
                setPdfDownloadName("");
                break;
        }
    }, [level, marks]);

    const decrementStep = useCallback(() => {
        setStep(step - 1);
        setStepDirection(-1);
    }, [step]);

    // if on the last step before confirmation and all tags are removed, move back one step
    useEffect(() => {
        if (step === -1) {
            navigate("/");
        }

        if (step === 2 && includedTags.size === 0 && excludedTags.size === 0) {
            decrementStep();
        }
    }, [navigate, decrementStep, includedTags, excludedTags, step]);

    // // TODO: Provide ability to view/add/edit questions related to the tag when the row is right clicked
    // function handleTagTableRowContextMenu(event) {
    //     event.preventDefault();

    //     const tagid = event.currentTarget.getAttribute('id');
    //     if (!tags[tagid].questions) { // no questions to show
    //         setAlertNoQuestionsForFullScreenDialogue(true);
    //         setTimeout(() => {
    //             setAlertNoQuestionsForFullScreenDialogue(false);
    //         }, 3000);
    //         return;
    //     }

    //     setFullScreenDialogue(tagid);
    // }

    // TODO: fix split/inclue toggle bar resetting to another position when moving back a step

    function openTagsDialogue(description) {
        setCurrentDescription(description);
        setTagsDialogue(true);
    }

    function closeTagsDialogue() {
        setTagsDialogue(false);
    }

    function getTagsToDisplayInDialogue() {
        return descriptions[currentDescription];
    }

    function setIncludeExclude(tags, setType) {
        let copySet;
        if (setType === "include") {
            copySet = new Set(tags);
        } else if (setType === "exclude") {
            copySet = new Set(tags);
        } else {
            return;
        }

        // can only be include/exclude at this point
        setType === "include"
            ? setIncludedTags(copySet)
            : setExcludedTags(copySet);
    }

    /**
     *
     * @param {array} tags string of ids
     * @param {string} addTo include | exclude
     */
    function addToIncludeExclude(tags, addTo) {
        let copySet;
        if (addTo === "include") {
            copySet = new Set(includedTags);
        } else if (addTo === "exclude") {
            copySet = new Set(excludedTags);
        } else {
            return;
        }

        for (let t of tags) copySet.add(t);

        // can only be include/exclude at this point
        addTo === "include"
            ? setIncludedTags(copySet)
            : setExcludedTags(copySet);
    }

    function removeFromIncludeExclude(tags, removeFrom) {
        let copySet;
        if (removeFrom === "include") {
            copySet = new Set(includedTags);
        } else if (removeFrom === "exclude") {
            copySet = new Set(excludedTags);
        } else {
            return;
        }

        for (let currTag of copySet) {
            for (let t of tags) {
                if (currTag === t) copySet.delete(t);
            }
        }

        // can only be include/exclude at this point
        removeFrom === "include"
            ? setIncludedTags(copySet)
            : setExcludedTags(copySet);
    }

    function clearIncludeExclude(clearType) {
        if (clearType === "include") {
            setIncludedTags(new Set());
        } else if (clearType === "exclude") {
            setExcludedTags(new Set());
        }
    }

    /**
     * @param {ids} str[]
    */
    function resetIncludeExclude(ids, type) {
        if (type !== "include" && type !== "exclude") return;
        type === "include" ? setIncludedTags(new Set(ids)) : setExcludedTags(new Set(ids));
    }

    function getAllIncludeExcludeTags(type) {
        if (type === "include") return Array.from(includedTags);
        else if (type === "exclude") return Array.from(excludedTags);
    }

    function incrementStep() {
        // do not increment to Create step if no tags are included or excluded
        if (step === 0 && includedTags.size === 0) {
            setAlertNotEnoughTags(true);
            setTimeout(() => {
                setAlertNotEnoughTags(false);
            }, 5000);
            return;
        }

        step + 1 > 2 ? setStep(2) : setStep(step + 1);
        setStepDirection(1);
    }

    function getAllTags() {
        return tags;
    }

    function getDescriptions() {
        return descriptions;
    }

    function getLevel() {
        return level;
    }

    function handleRenderStep(step) {
        switch (step) {
            case 0:
                return (
                    <Fragment>
                        {tagsLoadingAnimation ? (
                            <Progress></Progress>
                        ) : (
                            <div className="treeSelectTags">
                                <Tree
                                    getAllTags={getAllTags}
                                    getLevel={getLevel}
                                    type={"include"}
                                    getDescriptions={getDescriptions}
                                    allIncludeExcludeTags={
                                        (type) => getAllIncludeExcludeTags(type)
                                    }
                                    resetIncludeExclude={resetIncludeExclude}
                                    clearIncludeExclude={clearIncludeExclude}
                                ></Tree>
                            </div>
                        )}
                    </Fragment>
                );

            case 1:
                return (
                    <Fragment>
                        {tagsLoadingAnimation ? (
                            <Progress />
                        ) : (
                                <div key={stepDirection} className="treeSelectTags">
                                    <Tree
                                        step={step}
                                        stepDirection={stepDirection}
                                        getAllTags={getAllTags}
                                        getLevel={getLevel}
                                        type={"exclude"}
                                        getDescriptions={getDescriptions}
                                        allIncludeExcludeTags={
                                            (type) => getAllIncludeExcludeTags(type)
                                        }
                                        resetIncludeExclude={resetIncludeExclude}
                                        clearIncludeExclude={clearIncludeExclude}
                                    ></Tree>
                                </div>
                        )}
                    </Fragment>
                );

            case 2:
                return (
                    <div>
                        <EditSettingsDialogue
                            getCurrentPDFSettings={getCurrentPDFSettings}
                            changePDFSettings={changePDFSettings}
                        ></EditSettingsDialogue>
                        <hr></hr>
                        <div className="createStepPDFNameInput">
                            <DialogContent sx={{ color: "navy" }}>
                                <TextField
                                    sx={{ display: "flex", minWidth: "300px" }}
                                    autoFocus
                                    defaultValue={pdfDownloadName}
                                    margin="dense"
                                    size="small"
                                    id="pdfName"
                                    label="PDF Name"
                                    type="text"
                                    variant="outlined"
                                    inputProps={{ style: { color: "navy" } }}
                                    InputLabelProps={{
                                        style: { color: "navy" },
                                    }}
                                    onChange={(e) => {
                                        setPdfDownloadName(e.target.value);
                                    }}
                                />
                            </DialogContent>
                            <LoadingButtons
                                handleCreatePDF={handleCreatePDF}
                                isLoading={pdfCreationInProgress}
                            ></LoadingButtons>
                        </div>
                    </div>
                );
            default:
                break;
        }
    }
    ////////////////////////////////////////////////////////////////////////////////////////////////////////
    function displayPDFCreationAlert() {
        setAlertPDFCreationFailed(true);
        setTimeout(() => {
            setAlertPDFCreationFailed(false);
        }, 3000);
    }

    async function handleCreatePDF(event) {
        event.preventDefault();

        if(marks < NUM_MIN_MARKS || marks > NUM_MAX_MARKS) {
            displayPDFCreationAlert();
            return;
        }
        
        if (includedTags.size === 0 && excludedTags.size === 0) {
            setAlertNotEnoughTags(true);

            setTimeout(() => {
                setAlertNotEnoughTags(false);
            }, 3000);

            return;
        }

        setPDFCreationInProgress(true);
        const pdf = await createPDF(
            pdfDownloadName,
            "",
            level,
            marks,
            formula,
            split,
            includedTags,
            excludedTags
        );
        setPDFCreationInProgress(false);

        // remove colours
        if (!pdf) {
            displayPDFCreationAlert();
            return;
        }
    }

    function getCurrentPDFSettings() {
        return {
            split: split,
            formula: formula,
            marks: marks,
        };
    }

    function changePDFSettings(split, formula, marks) {
        setSplit(split);
        setFormula(formula);
        setMarks(marks);
    }

    function sumTotalIncludeExcludeMarks(type) {
        const arr =
            type === "include"
                ? Array.from(includedTags)
                : Array.from(excludedTags);
        return arr
            .map((t) =>
                tags[t].questions ? Object.keys(tags[t].questions).length : 0
            )
            .reduce((prev, curr) => {
                return prev + curr;
            }, 0);
    }

    function displayEditSettings(open) {
        if (open) setDisplayEditSettingsDialogue(true);
        else setDisplayEditSettingsDialogue(false);
    }

    return (
        <div className="App">
            <div>
                <CustomizedSteppers currentStep={step}></CustomizedSteppers>

                <div className="headerSeparation">{handleRenderStep(step)}</div>

                <div className="stepThroughButtons">
                    <ArrowCircleLeftIcon
                        sx={{fontSize: 60}}
                        style={{
                            cursor: "pointer",
                            color: "#1a17f9",
                            textAlign: "center",
                        }}
                        onClick={() => {
                            decrementStep();
                        }}
                        fontSize="large"
                    ></ArrowCircleLeftIcon>
                    <ArrowCircleRightIcon
                        sx={{ color: step === 2 ? "#ffffff" : "#1a17f9", fontSize: 60}}
                        style={
                            step !== 2
                                ? {
                                    cursor: "pointer",
                                    color: "#1a17f9",
                                    position: "relative",
                                }
                                : {
                                    cursor: "auto",
                                    color: "#ffffff",
                                }
                        }
                        onClick={() => {
                            incrementStep();
                        }}
                        fontSize="large"
                    ></ArrowCircleRightIcon>
                </div>

                {displayEditSettingsDialogue && (
                    <EditSettingsDialogue
                        getCurrentPDFSettings={getCurrentPDFSettings}
                        displayEditSettings={displayEditSettings}
                        changePDFSettings={changePDFSettings}
                    ></EditSettingsDialogue>
                )}

                {
                    // show alert if not enough tags are selected
                    alertNotEnoughTags && (
                        <CustomizedSnackbars
                            severity="error"
                            message="We can't give you questions if you don't include any topics!"
                        />
                    )
                }

                {
                    // show alert if pdf creation failed
                    alertPDFCreationFailed && (
                        <CustomizedSnackbars
                            severity="error"
                            message="Failed to create PDF!"
                        />
                    )
                }

                {
                    // show alert if not enough tags are selected
                    alertNoQuestionsForFullScreenDialogue && (
                        <CustomizedSnackbars
                            severity="warning"
                            message="No questions present!"
                        />
                    )
                }

                {tagsDialogue && (
                    <TagsDialogue
                        closeAction={closeTagsDialogue}
                        allTags={tags}
                        tags={getTagsToDisplayInDialogue}
                        getAllIncludeExcludeTags={getAllIncludeExcludeTags}
                        addToIncludeExclude={addToIncludeExclude}
                        setIncludeExclude={setIncludeExclude}
                    ></TagsDialogue>
                )}
            </div>
            {/* {fullScreenDialogue && (
                <SignIn></SignIn>
                // <FullScreenDialog
                //     isDialogueOpen={fullScreenDialogue}
                //     dialogueOpenState={setFullScreenDialogue}
                //     tags={tags}
                // ></FullScreenDialog>
            )} */}
        </div>
    );
}

export default App;
