import * as React from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";

export default function PDFConfirmationButton(props) {
    return (
        <LoadingButton
            loading={props.isLoading}
            loadingPosition="start"
            startIcon={<SaveIcon />}
            variant="outlined"
            sx={{
                width: "85%",
                "& .MuiButton-startIcon": {
                    color: "black ",
                },
                "& .MuiLoadingButton-root": {
                    color: "black ",
                },
                "& .MuiLoadingButton-endIconLoadingEnd": {
                    backgroundColor: "black"
                }
            }}
            onClick={props.handleCreatePDF}
        >
            Create PDF
        </LoadingButton>
    );
}
