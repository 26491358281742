import * as React from "react";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

export default function BasicSelect(props) {
    const [level, setLevel] = React.useState("2U");

    React.useEffect(() => {
        setLevel(props.initialValue);
    }, []);

    const handleChange = (event) => {
        props.setLevel(event.target.value);
        setLevel(event.target.value);
    };

    return (
        <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth size="small">
                <Select
                    id="select-level"
                    value={level}
                    onChange={handleChange}
                    sx={{
                        color: "black",
                    }}
                    MenuProps={{ MenuListProps: { disablePadding: true } }}
                >
                    {props.options.map((lvl) => {
                        return (
                            <MenuItem
                                key={lvl}
                                value={lvl}
                                sx={{
                                    color: "black",
                                    "&:hover": {
                                        color: "black",
                                    },
                                    "&.Mui-selected": {
                                        color: "black",
                                        "&:hover": {
                                            color: "black",
                                        },
                                    },
                                }}
                            >
                                {" "}
                                {lvl}{" "}
                            </MenuItem>
                        );
                    })}
                </Select>
            </FormControl>
        </Box>
    );
}
